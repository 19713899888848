export const activities = ["Foot5", "Padel", "Squash", "Badminton"];

export const offers = [
    {title: "Simple", icon: "simple.svg"},
    {title: "Rapide", icon: "rapide.svg"},
    {title: "Efficace", icon: "efficace.svg"},
];

export const home =
    "radial-gradient(169.2% 780.97% at -26.56% 40.37%, #0D67D6 0%, #1378E5 100%)";

export const menu1 = [
    {
        name: "Réservation",
        color: "#B228B5",
        background:
            " linear-gradient(70.33deg, #B372BD 32.84%, rgba(139, 96, 237, 0.67) 100.54%)",
        info:
            "Planification et gestion\n" +
            "des réservations.\n" +
            "\n" +
            "Gagnez du temps en laissant vos clients réserver en ligne 24h/24 et 7j/7.\n" +
            "\n" +
            "Profitez d’un agenda spécialisé et personnalisé.",
        url: "reservation",
    },
    {
        name: "CRM",
        color: "#1378E5",
        background: " linear-gradient(70.33deg, #637BFF 32.84%, #21C8F6 100.54%)",
        info:
            "Communiquez facilement avec vos clients.\n" +
            "\n" +
            "Créez sans effort une véritable base client avec toutes les informations nécessaire pour communiquer efficacement.",
        url: "crm",
    },
    {
        name: "Compétition",
        color: "#126F5A",
        background: "linear-gradient(70.33deg, #52C234 32.84%, #CAFFB7 100.54%)",
        info:
            "Un système intégral de gestion de compétitions.\n" +
            "\n" +
            "Championnat, tournoi en mode manuel ou automatique, offrez la meilleure expérience de jeu à vos clients.\n" +
            "\n",
        url: "competition",
    },
    {
        name: "Booking",
        color: "#93EDC7",
        background: "linear-gradient(70.33deg, #1CD8D2 32.84%, #93EDC7 100.54%)",
        info:
            "Remplissez plus facilement vos créneaux disponibles.\n" +
            "\n" +
            "Créer une véritable communauté en permettant à vos clients de trouver des joueurs.",
        url: "booking",
    },
];

export const menu3 = [
    {
        name: "Réservation",
        color: "#B228B5",
        background:
            " linear-gradient(70.33deg, #B372BD 32.84%, rgba(139, 96, 237, 0.67) 100.54%)",
        info:
            "Planification et gestion\n" +
            "des réservations.\n" +
            "\n" +
            "Gagnez du temps en laissant vos clients réserver en ligne 24h/24 et 7j/7.\n" +
            "\n" +
            "Profitez d’un agenda spécialisé et personnalisé.",
        url: "reservation",
    },
    {
        name: "CRM",
        color: "#1378E5",
        background: " linear-gradient(70.33deg, #637BFF 32.84%, #21C8F6 100.54%)",
        info:
            "Communiquez facilement avec vos clients.\n" +
            "\n" +
            "Créez sans effort une véritable base client avec toutes les informations nécessaire pour communiquer efficacement.",
        url: "crm",
    },
    {
        name: "Caisse",
        color: "#F44881",
        background:
            "linear-gradient(70.33deg, #F44881 32.84%, rgba(236, 69, 79, 0.67) 100.54%)",
        info:
            "Gérez vos encaissement rapidement.\n" +
            "\n" +
            " Avec notre logiciel de caisse intuitif et convivial, \n" +
            "vous trouverez toutes les fonctions dont vous avez besoin.",
        url: "caisse",
    },
    {
        name: "Booking",
        color: "#93EDC7",
        background: "linear-gradient(70.33deg, #1CD8D2 32.84%, #93EDC7 100.54%)",
        info:
            "Remplissez plus facilement vos créneaux disponibles.\n" +
            "\n" +
            "Créer une véritable communauté en permettant à vos clients de trouver des joueurs.",
        url: "booking",
    },
    {
        name: "Abonnement",
        color: "#4B48F4",
        background:
            "linear-gradient(57.23deg, #4B48F4 32.84%, #ECEBFC 100.54%)",
        info:
            "Vendez des abonnements à vos clients.\n" +
            "\n" +
            "Notre module flexible pour la gestion des adhésions s’adapte au fonctionnement de votre entreprise.",
        url: "abonnement",
    },
    {
        name: "Compétition",
        color: "#126F5A",
        background: "linear-gradient(70.33deg, #52C234 32.84%, #CAFFB7 100.54%)",
        info:
            "Un système intégral de gestion de compétitions.\n" +
            "\n" +
            "Championnat, tournoi en mode manuel ou automatique, offrez la meilleure expérience de jeu à vos clients.\n" +
            "\n",
        url: "competition",
    },
];
export const menu4=[
    {
        name: "Video",
        color: "#FF512F",
        background: "linear-gradient(70.33deg, #FF512F 32.84%, #F09819 100.54%)",
        info:
            "Communiquez facilement avec vos clients.\n" +
            "\n" +
            "Créez sans effort une véritable base client avec toutes les informations nécessaire pour communiquer efficacement.",
        url: "video",
    },
    {
        name: "Scoring",
        color: "#B228B5",
        background:
            "linear-gradient(52.33deg, #833AB4 0%, #FD1D1D 56.76%, #FCB045 93.85%)",
        info:
            "Suivez le score des matchs  en toute simplicité.\n" +
            "\n" +
            "Nos applications mobile vous permet un affichage sportif  des principales informations de jeu mais également publicitaire.\n" +
            "\n",
        url: "scoring",
    },
];

export const menu2=[
    {
        name: "Caisse",
        color: "#F44881",
        background:
            "linear-gradient(70.33deg, #F44881 32.84%, rgba(236, 69, 79, 0.67) 100.54%)",
        info:
            "Gérez vos encaissement rapidement.\n" +
            "\n" +
            " Avec notre logiciel de caisse intuitif et convivial, \n" +
            "vous trouverez toutes les fonctions dont vous avez besoin.",
        url: "caisse",
    },
    {
        name: "CRM",
        color: "#1378E5",
        background: " linear-gradient(70.33deg, #637BFF 32.84%, #21C8F6 100.54%)",
        info:
            "Communiquez facilement avec vos clients.\n" +
            "\n" +
            "Créez sans effort une véritable base client avec toutes les informations nécessaire pour communiquer efficacement.",
        url: "crm",
    },
    {
        name: "Abonnement",
        color: "#4B48F4",
        background:
            "linear-gradient(57.23deg, #4B48F4 32.84%, #ECEBFC 100.54%)",
        info:
            "Vendez des abonnements à vos clients.\n" +
            "\n" +
            "Notre module flexible pour la gestion des adhésions s’adapte au fonctionnement de votre entreprise.",
        url: "abonnement",
    },
];

export const menu = [
    {
        name: "Réservation",
        color: "#B228B5",
        background:
            " linear-gradient(70.33deg, #B372BD 32.84%, rgba(139, 96, 237, 0.67) 100.54%)",
        info:
            "Planification et gestion\n" +
            "des réservations.\n" +
            "\n" +
            "Gagnez du temps en laissant vos clients réserver en ligne 24h/24 et 7j/7.\n" +
            "\n" +
            "Profitez d’un agenda spécialisé et personnalisé.",
        url: "reservation",
    },
    {
        name: "CRM",
        color: "#1378E5",
        background: " linear-gradient(70.33deg, #637BFF 32.84%, #21C8F6 100.54%)",
        info:
            "Communiquez facilement avec vos clients.\n" +
            "\n" +
            "Créez sans effort une véritable base client avec toutes les informations nécessaire pour communiquer efficacement.",
        url: "crm",
    },
    {
        name: "Caisse",
        color: "#F44881",
        background:
            "linear-gradient(70.33deg, #F44881 32.84%, rgba(236, 69, 79, 0.67) 100.54%)",
        info:
            "Gérez vos encaissement rapidement.\n" +
            "\n" +
            " Avec notre logiciel de caisse intuitif et convivial, \n" +
            "vous trouverez toutes les fonctions dont vous avez besoin.",
        url: "caisse",
    },
    {
        name: "Video",
        color: "#FF512F",
        background: "linear-gradient(70.33deg, #FF512F 32.84%, #F09819 100.54%)",
        info:
            "Communiquez facilement avec vos clients.\n" +
            "\n" +
            "Créez sans effort une véritable base client avec toutes les informations nécessaire pour communiquer efficacement.",
        url: "video",
    },
    {
        name: "Abonnement",
        color: "#4B48F4",
        background:
            "linear-gradient(57.23deg, #4B48F4 32.84%, #ECEBFC 100.54%)",
        info:
            "Vendez des abonnements à vos clients.\n" +
            "\n" +
            "Notre module flexible pour la gestion des adhésions s’adapte au fonctionnement de votre entreprise.",
        url: "abonnement",
    },
    {
        name: "Compétition",
        color: "#126F5A",
        background: "linear-gradient(70.33deg, #52C234 32.84%, #CAFFB7 100.54%)",
        info:
            "Un système intégral de gestion de compétitions.\n" +
            "\n" +
            "Championnat, tournoi en mode manuel ou automatique, offrez la meilleure expérience de jeu à vos clients.\n" +
            "\n",
        url: "competition",
    },
    {
        name: "Scoring",
        color: "#B228B5",
        background:
            "linear-gradient(52.33deg, #833AB4 0%, #FD1D1D 56.76%, #FCB045 93.85%)",
        info:
            "Suivez le score des matchs  en toute simplicité.\n" +
            "\n" +
            "Nos applications mobile vous permet un affichage sportif  des principales informations de jeu mais également publicitaire.\n" +
            "\n",
        url: "scoring",
    },
    {
        name: "Booking",
        color: "#93EDC7",
        background: "linear-gradient(70.33deg, #1CD8D2 32.84%, #93EDC7 100.54%)",
        info:
            "Remplissez plus facilement vos créneaux disponibles.\n" +
            "\n" +
            "Créer une véritable communauté en permettant à vos clients de trouver des joueurs.",
        url: "booking",
    },
];

export const row1 = ["0.png", "1.png", "2.png", "3.png"];
export const row2 = ["4.png", "5.png", "6.png", "7.png", "8.png"];


export const system1 = [
    {
        title: "Abonnements",
        description: "Carte prépayée, abo annuel ",
        img: '1.svg',
        width: 340,
        height: 319,
        image:"abo.png"
    },
    {
        title: "Reporting",
        description: "Fréquentation, panier moyen",
        img: '2.svg',
        width: 337,
        height: 328,
        image:"reporting.png"
    },
    {
        title: "Caisse",
        description: "Bar, resto, multi-Activités",
        img: '3.svg',
        width: 243,
        height: 199,
        image:"caisse.png"
    },
];

export const system2 = [
    {
        title: "Outils marketing",
        description: "Mails, Notifications",
        img: '4.svg',
        width: 280,
        height: 230,
        image:"marketing.png"
    },
    {
        title: "App Joueur",
        description: "Stats de jeu, compte perso",
        img: '5.svg',
        width: 282,
        height: 257,
        image:"joueur.png"
    },
    {
        title: "Gestion des réservations",
        description: "en ligne, récurrence ...",
        img: '6.svg',
        width: 377,
        height: 314,
        image:"res.png"
    },
    {
        title: "Gestion d'événements",
        description: "anniversaire, location, séminaire, EVG",
        img: '7.svg',
        width: 308,
        height: 184,
        image:"evenement.png"
    },
];

export const system3 = [
    {
        title: "Paiement en ligne",
        description: "Prélèvement, virement auto",
        img: '8.svg',
        width: 276,
        height: 239,
        image:"paiement.png"
    },
    {
        title: "Compétition",
        description: "Championnat, tournoi",
        img: '9.svg',
        width: 271,
        height: 259,
        image:"comp.png"
    },
    {
        title: "Vidéo",
        description: "Live & Replay",
        img: '10.svg',
        width: 247,
        height: 210,
        image:"video.png"
    },
    {
        title: "Scoring",
        description: "Digital, séquencage",
        img: '11.svg',
        width: 269,
        height: 236,
        image:"scoring.png"
    },
    {
        title: "App manager",
        description: "info en temps réel",
        img: '12.svg',
        width: 249,
        height: 234,
        image:"manager.png",
    },
];

export const tarifs =[
    {
        name: "Essentiel",
        color: "#B228B5",
        background:
            "linear-gradient(62.22deg, #52C234 32.84%, #CAFFB7 100.54%)",
        info:
            "Suivez le score des matchs  en toute simplicité.\n" +
            "\n" +
            "Nos applications mobile vous permet un affichage sportif  des principales informations de jeu mais également publicitaire.\n" +
            "\n",
        url: "essential",
    },
    {
        name: "Sérénité",
        color: "#B228B5",
        background:
            "linear-gradient(63.68deg, #654EA3 -10.93%, rgba(234, 175, 200, 0.67) 102.79%)",
        info:
            "Suivez le score des matchs  en toute simplicité.\n" +
            "\n" +
            "Nos applications mobile vous permet un affichage sportif  des principales informations de jeu mais également publicitaire.\n" +
            "\n",
        url: "senerte",
    },
    {
        name: "Vidéo",
        color: "#B228B5",
        background:
            "linear-gradient(59.9deg, #FF512F -2.64%, #FF512F -2.63%, #F09819 96.26%)",
        info:
            "Suivez le score des matchs  en toute simplicité.\n" +
            "\n" +
            "Nos applications mobile vous permet un affichage sportif  des principales informations de jeu mais également publicitaire.\n" +
            "\n",
        url: "play",
    },
];